var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        footer: {
          text: "Need help?",
          linkTitle: "Contact support",
          link: "mailto:support@xinfin.org",
        },
        "show-overlay": _vm.open,
        title: "Access Wallet with Mobile Apps",
        "content-size": "large",
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "mx-auto mb-n5 pt-5",
          staticStyle: { "max-width": "650px", width: "100%" },
        },
        _vm._l(_vm.buttons, function (btn, key) {
          return _c(
            "div",
            { key: key },
            [
              _c(
                "mew-button",
                {
                  staticClass: "mb-5",
                  staticStyle: { height: "160px" },
                  attrs: {
                    "has-full-width": "",
                    "color-theme": "greyMedium",
                    "btn-style": "outline",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return btn.fn.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-space-between px-2",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mew-heading-2 textDark--text" },
                        [_vm._v(_vm._s(btn.label))]
                      ),
                      _c("v-img", {
                        staticClass: "px-4 px-sm-3",
                        attrs: {
                          src: btn.icon,
                          "max-width": "90px",
                          "min-width": "40px",
                          contain: "",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }